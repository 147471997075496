import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
// 
import ONESO from '../images/1S_ST-1/1S_ST-1_0priz.jpg'
import ONEST from '../images/1S_ST-1/1S_ST-1_1kat.jpg'
import TWOST from '../images/1S_ST-1/1S_ST-1_2kat.jpg'
import ONEPV from '../images/1S_ST-1/1S_ST-1_povrs.jpg'

import TWOESO from '../images/1S_ST-2/1S_ST-2_0priz.jpg'
import TWOEST from '../images/1S_ST-2/1S_ST-2_1kat.jpg'
import THREEST from '../images/1S_ST-2/1S_ST-2_2kat.jpg'
import TWOPV from '../images/1S_ST-2/1S_ST-2_povrs.jpg'

import THREEESO from '../images/2J_ST-1/2J_ST-1_0priz.jpg'
import THREEEST from '../images/2J_ST-1/2J_ST-1_1kat.jpg'
import FOUREST from '../images/2J_ST-1/2J_ST-1_2kat.jpg'
import THREEPV from '../images/2J_ST-1/2J_ST-1_povrs.jpg'

import FOURESO from '../images/2J_ST-2/2J_ST-2_0priz.jpg'
import FOUREIST from '../images/2J_ST-2/2J_ST-2_1kat.jpg'
import FIVEEST from '../images/2J_ST-2/2J_ST-2_2kat.jpg'
import FIVEPV from '../images/2J_ST-2/2J_ST-2_povrs.jpg'

import SIT1 from '../images/SIT-1S_ST1.jpg'
import SIT2 from '../images/SIT-1S_ST2.jpg'
import SIT3 from '../images/SIT-2J_ST1.jpg'
import SIT4 from '../images/SIT-2J_ST2.jpg'

import R1 from '../images/prilozi/1.jpg'
import R2 from '../images/prilozi/2.jpg'
import R3 from '../images/prilozi/3.jpg'
import R4 from '../images/prilozi/4.jpg'


import K5 from '../images/5.jpg'
import K7 from '../images/7.jpg'
import K15 from '../images/15.jpg'
import K3 from '../images/3.jpg'


import K19 from '../images/19.jpg'
import K18 from '../images/18.jpg'
import K16 from '../images/16.jpg'
import K15r from '../images/15.jpg'
import K14 from '../images/14.jpg'



import K25 from '../images/25.jpg'
import K26 from '../images/26.jpg'
import K27 from '../images/27.jpg'
import K28 from '../images/28.jpg'
import K29 from '../images/29.jpg'
import K30 from '../images/30.jpg'
import K31 from '../images/31.jpg'

import K32 from '../images/32.jpg'
import K33 from '../images/33.jpg'


import N1 from '../images/kasli1.jpg'
import N2 from '../images/kasli2.jpg'
import N3 from '../images/kasli3.jpg'
import N4 from '../images/kasli4.jpg'
import N5 from '../images/kasli5.jpg'
import N6 from '../images/kasli6.jpg'

import N100 from '../images/100.jpg'
import N101 from '../images/101.jpg'
import N102 from '../images/102.jpg'
import N103 from '../images/103.jpg'
import N104 from '../images/104.jpg'
import N105 from '../images/105.jpg'
import N106 from '../images/106.jpg'
import N107 from '../images/107.jpg'
import N108 from '../images/108.jpg'
import N109 from '../images/109.jpg'



import Z1 from '../images/z1.jpg'
import Z2 from '../images/z2.jpg'
import Z3 from '../images/z3.jpg'
import Z4 from '../images/z4.jpg'
import Z5 from '../images/z5.jpg'
import Z6 from '../images/z6.jpg'








import Container from 'react-bootstrap/Container';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect, useState } from 'react';



export default function Properties() {


  const [displayPlans, setDisplayPlans] = useState(true);

  useEffect(() => {
    Aos.init({duration: 750});
    setTimeout(() => {
      Aos.refresh();
    }, 100);
  }, []);



  return (
    <Container className="pics-container" fluid>


    <ButtonGroup className="btn-group">
      <Button id="btn" className={`btn ${displayPlans ? 'active' : ''}`} onClick={() => setDisplayPlans(true)}>
        Plans
      </Button>
      <Button id="btn" className={`btn ${displayPlans ? 'active' : ''}`} onClick={() => setDisplayPlans(false)}>
        Current state
      </Button>
    </ButtonGroup>


    { displayPlans ? 
     <>
     <h2 data-aos="fade-right" className="flat-title one" >Villa Dina 1</h2>

            <Carousel interval={null} indicators={false} data-aos="fade-left" className="pics-carousel">
              <Carousel.Item>
                <img
                  src={SIT1}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={ONESO}
                  alt="First slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={ONEST}
                  alt="Second slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={TWOST}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={ONEPV}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
            </Carousel>

            <h2 data-aos="fade-right" className="flat-title">Villa Dina 2</h2>

            <Carousel indicators={false}  data-aos="fade-left" className="pics-carousel">
              <Carousel.Item>
                <img
                  src={SIT2}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={TWOESO}
                  alt="First slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={TWOEST}
                  alt="Second slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={THREEST}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={TWOPV}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
            </Carousel>





            <h2 data-aos="fade-right" className="flat-title">Villa Laura 1</h2>

            <Carousel indicators={false}  data-aos="fade-left" className="pics-carousel">
              <Carousel.Item>
                <img
                  src={SIT3}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={THREEESO}
                  alt="First slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={THREEEST}
                  alt="Second slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={FOUREST}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={THREEPV}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
            </Carousel>



            <h2 data-aos="fade-right" className="flat-title">Villa Laura 2</h2>

            <Carousel indicators={false}  data-aos="fade-left" className="pics-carousel">
              <Carousel.Item>
                <img
                  src={SIT4}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={FOURESO}
                  alt="First slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={FOUREIST}
                  alt="Second slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={FIVEEST}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={FIVEPV}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
            </Carousel>
            </>
            : 
            <>
            <h2 data-aos="fade-right" className="flat-title one" > 18.10.2023.</h2>
            <Carousel indicators={false}  data-aos="fade-left" className="pics-carousel">
            <Carousel.Item>
              <img
                src={R1}
                alt="Third slide"
                className="pics-pic d-block w-50"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={R2}
                alt="First slide"
                className="pics-pic d-block w-50"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={R3}
                alt="Second slide"
                className="pics-pic d-block w-50"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={R4}
                alt="Third slide"
                className="pics-pic d-block w-50"
              />
            </Carousel.Item>
          </Carousel>




          <h2 data-aos="fade-right" className="flat-title one" > 20.12.2023.</h2>

          <Carousel indicators={false}  data-aos="fade-left" className="pics-carousel">
              <Carousel.Item>
                <img
                  src={K5}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={K7}
                  alt="First slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={K5}
                  alt="Second slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={K15}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={K3}
                  alt="Third slide"
                  className="pics-pic d-block w-50"
                />
              </Carousel.Item>
            </Carousel>


        <h2 data-aos="fade-right" className="flat-title one" > 2.2.2024</h2>

        <Carousel indicators={false}  data-aos="fade-left" className="pics-carousel">
            <Carousel.Item>
              <img
                src={K19}
                alt="Third slide"
                className="pics-pic d-block w-50"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={K18}
                alt="First slide"
                className="pics-pic d-block w-50"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={K16}
                alt="Second slide"
                className="pics-pic d-block w-50"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={K15r}
                alt="Third slide"
                className="pics-pic d-block w-50"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={K14}
                alt="Third slide"
                className="pics-pic d-block w-50"
              />
            </Carousel.Item>
          </Carousel>


          <h2 data-aos="fade-right" className="flat-title one" > 5.3.2024</h2>

<Carousel indicators={false}  data-aos="fade-left" className="pics-carousel">
    <Carousel.Item>
      <img
        src={N1}
        alt="Third slide"
        className="pics-pic d-block w-50"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        src={N2}
        alt="First slide"
        className="pics-pic d-block w-50"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        src={N3}
        alt="Second slide"
        className="pics-pic d-block w-50"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        src={N4}
        alt="Third slide"
        className="pics-pic d-block w-50"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        src={N5}
        alt="Third slide"
        className="pics-pic d-block w-50"
      />
    </Carousel.Item>
  </Carousel>




<h2 data-aos="fade-right" className="flat-title one" > 20.6.2024</h2>

<Carousel indicators={false}  data-aos="fade-left" className="pics-carousel">
<Carousel.Item>
<img
src={K25}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={K26}
alt="First slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={K27}
alt="Second slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={K28}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={K29}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={K30}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={K31}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>





</Carousel>




<h2 data-aos="fade-right" className="flat-title one" > 28.8.2024</h2>

<Carousel indicators={false}  data-aos="fade-left" className="pics-carousel">
<Carousel.Item>
<img
src={K32}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={K33}
alt="First slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
</Carousel>




<h2 data-aos="fade-right" className="flat-title one" > 20.11.2024</h2>

<Carousel indicators={false}  data-aos="fade-left" className="pics-carousel">
<Carousel.Item>
<img
src={N101}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={N102}
alt="First slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={N104}
alt="Second slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={N106}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={N107}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={N108}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={N109}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>





</Carousel>




<h2 data-aos="fade-right" className="flat-title one" > 7.1.2025</h2>

<Carousel indicators={false}  data-aos="fade-left" className="pics-carousel">
<Carousel.Item>
<img
src={Z1}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={Z2}
alt="First slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={Z3}
alt="Second slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={Z4}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={Z5}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
<img
src={Z6}
alt="Third slide"
className="pics-pic d-block w-50"
/>
</Carousel.Item>
<Carousel.Item>
</Carousel.Item>





</Carousel>

          </>
            

            }


    </Container>
  )
}